.validationflow .react-flow__node {
  width: 150px;
  border-radius: 5px;
   padding: 10px; 

  color: #000000 !important;
  border: 1px solid #ddd;
  text-align: center;
  /* overflow-y: "scroll"; */
  font-size: 10px;
}

.validationflow .react-flow__node-group {
  /* background: #fff; */
  /* //border: 1px solid rgb(0, 0, 0); */
  /* width:300px !important;
  border-radius: 10px;
  color: #000000 !important; */
  /* background: #ffffff */
  /* height: auto; */
  /* margin-right: 40px !important; */
  /* overflow-y: scroll !important; */
  /* overflow: hidden !important; */
  /* min-height: 100vh !important; */
  /* display: flex !important;
  flex-wrap: wrap !important; */
  display: none !important;
}

.validationflow .react-flow__node-customnode {
   background: #dfdfdf; 
  /* box-shadow: 1px 1px #f9f8fa !important; */
  z-index: 5 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px, rgba(0, 0, 0, 0.22) 0px 1px 2px;
  /* display: flex; */
  justify-content: center;

  /* //min-width:190px !important; */

  /* //color: #4764b4 !important; */
}

.react-flow__node-custominput .react-flow__handle {
  background: #82590d;
  color: #000000 !important;
}

.validationflow .react-flow__node-custominput {
  background: rgb(5, 92, 66);
  color: #000000 !important;
}

.validationflow .react-flow__handle-connecting {
  background: #031737;
  color: #000000 !important;
}

.validationflow .react-flow__handle-valid {
  background: #55dd99;
  color: #000000 !important;
}
.validationflow .react-flow__edge-path {
  stroke: #003ce2 !important;
  stroke-width: 2px !important;
}

.validationflow .react-flow__handle {
  width: 10px !important;
  height: 10px !important;
  background: #003ce2 !important;
}
.validationflow .react-flow__node react-flow__node-default nopan selectable {
  height: 100vmax !important;
}
.validationflow .react-flow__attribution {
  display: none !important;
}

.textColor {
  text-transform: capitalize;
  color: #000;
}

